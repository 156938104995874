// ! ---------- LAYOUT


.is-coming-soon {
    .logo, .page__footer, .page__header {
        display: none; } }

.page__header,
.header-placeholder {
    width: 100%;
    height: $header-height--nav-as-overlay;
    @include layout--nav-always-visible {
        height: $header-height--nav-always-visible; } }

.page__header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $z-index-header;
    display: flex;
    flex-direction: row;
    background: palette('background');
    border-bottom: 2px solid palette('highlight');
    transition: transition(background);
    @extend %accent-typography;

    .template-home & {
        background: palette('highlight');
        color: palette('text-inverted');
        border-color: palette('text-inverted'); }

    .is-nav-visible & {
        @include layout--nav-as-overlay {
            background: palette('highlight');
            color: palette('text-inverted');
            border-color: palette('text-inverted'); } } }

.logo {
    flex-grow: 1 {
 } }        //padding-left: 2rem

%nav-link-style {
    padding: 0 1em;
    transition: transition(color background opacity);
    text-transform: lowercase; }

%nav-link-style--active {
    background: palette('highlight');
    color: palette('text-inverted'); }

%nav-link-style--active-inverted {
    background: palette('text-inverted');
    color: palette('highlight'); }

.navigation {
    margin-left: auto;
    display: flex;
    flex-direction: row;

    @include layout--nav-as-overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        ul {
            width: 100%; }
        font-size: ($fontsize-l + $fontsize-l-xl) / 2;
        padding-top: $header-height--nav-as-overlay;
        pointer-events: none;
        opacity: 0;
        z-index: $z-index-navigation;

        .is-loaded & {
            transition: transition(opacity); }
        .is-resizing & {
            transition: none; }
        .is-nav-visible & {
            pointer-events: auto;
            opacity: 1; } }

    ul {
        display: flex;
        flex-direction: row;

        @include layout--nav-as-overlay {
            flex-direction: column;
            background: palette('highlight'); } }

    li {
        display: flex;
        flex-direction: column;
        // justify-content: stretch
        // align-items: stretch
        border: 2px solid palette('highlight');
        border-width: 0 0 0 2px;
        .template-home & {
            border-color: palette('text-inverted'); }
        @include layout--nav-as-overlay {
            display: block;
            border-color: palette('text-inverted');
            border-width: 0 0 2px 0;
            text-align: center;
            flex: 1;
            &:last-child {
                border-width: 0; } } }

    a {
        flex-basis: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @extend %nav-link-style; }

    a:hover,
    .is-current a {
        @extend %nav-link-style--active;
        .template-home & {
            @extend %nav-link-style--active-inverted; } } }


.navigation-toggle-on {
    display: flex;
    color: palette('highlight');
    border-left: 2px solid palette('highlight');
    cursor: pointer;
    .template-home & {
        color: palette('text-inverted');
        border-color: palette('text-inverted'); }

    @include layout--nav-always-visible {
        display: none; }

    label {
        flex-basis: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
        @extend %nav-link-style;

        &:hover {
            @extend %nav-link-style--active; }

        span:first-child {
            &::after {
                content: attr(data-text); } } }

    .is-nav-visible & {
        opacity: 0;
        label {
            @extend %nav-link-style--active; } } }

.navigation-toggle-off {
    @include layout--nav-always-visible {
        display: none; }

    position: absolute;
    top: 0;
    right: 0;
    width: $header-height--nav-as-overlay * 1.35;
    height: $header-height--nav-as-overlay;
    border-left: 2px solid palette('text-inverted');
    border-bottom: 2px solid palette('text-inverted');
    cursor: pointer;
    background: palette('highlight');

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: $header-height--nav-as-overlay * 3/4;
        height: 2px;
        background: palette('text-inverted'); }

    &::before {
        transform: translate(-50%, -50%) rotate(-45deg); }

    &::after {
        transform: translate(-50%, -50%) rotate(45deg); } }

.language-switcher {
    color: palette('highlight');
    border-left: 2px solid palette('highlight');
    .template-home & {
        border-color: palette('text-inverted'); }

    display: flex;

    ul {
        display: flex;
        flex-direction: column;
        overflow: hidden; }
    li {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-basis: 50%;
        text-align: center;
        overflow: hidden;
        > a, > span {
            padding: .5em 1.1em; }
        a:hover {
            transition: transition(color background);
            @extend %nav-link-style--active;
            .template-home & {
                @extend %nav-link-style--active-inverted; } }

        border-bottom: 2px solid palette('highlight');
        .template-home & {
            border-color: palette('text-inverted');
            color: palette('text-inverted'); }
        &:last-child {
            border-width: 0; } } }

.page__content {
    background: palette('background'); }
