// ! ---------- DEBUG

@mixin debug-bg($color) {
    @if $debug {
        background-color: $color; } }



// ! ---------- NAMED BREAKPOINTS / LAYOUTS

@mixin layout--nav-always-visible {
    @include breakpoint(large) {
        @content; } }

@mixin layout--nav-as-overlay {
    @include breakpoint(medium down) {
        @content; } }

@mixin layout--multi-column {
    @include breakpoint(medium) {
        @content; } }

@mixin layout--single-column {
    @include breakpoint(small down) {
        @content; } }



// ! ---------- GET MEASUREMENTS FROM GRID GUTTER

$vertical-factor: 0.8;

@mixin grid-gutter-property($property, $gutter: $grid-column-gutter, $factor: 1) {

    @if type-of($gutter) == 'map' {
        @each $breakpoint, $value in $gutter {
            $numerical: rem-calc($value) * $factor;

            @include breakpoint($breakpoint) {
                #{$property}: $numerical; } } }

    @else if type-of($gutter) == 'number' and strip-unit($gutter) > 0 {
        $numerical: rem-calc($gutter) * $factor;

        @each $side in $sides {
            #{$property}: $numerical; } } }

@mixin grid-gutter-margin($sides: top bottom, $factor: 1) {
    @each $side in $sides {
        $property: margin-#{$side};
        $property-factor: $factor;

        @if $side == 'top' or $side == 'bottom' {
            $property-factor: $factor * $vertical-factor; }

        @include grid-gutter-property($property, $factor: $factor); } }

@mixin grid-gutter-padding($sides: top bottom, $factor: 1) {
    @each $side in $sides {
        $property: padding-#{$side};
        $property-factor: $factor;

        @if $side == 'top' or $side == 'bottom' {
            $property-factor: $factor * $vertical-factor; }

        @include grid-gutter-property($property, $factor: $factor); } }

@mixin grid-gutter-position($sides: top, $factor: 1) {
    @each $side in $sides {
        $property-factor: $factor;

        @if $side == 'top' or $side == 'bottom' {
            $property-factor: $factor * $vertical-factor; }

        @include grid-gutter-property($side, $factor: $factor); } }



// ! ---------- GET MEASUREMENTS FROM GLOBAL PADDING

@mixin global-padding($sides: top right bottom left, $factor: 1) {
    @each $side in $sides {
        $property: padding-#{$side};
        @include grid-gutter-property($property, $gutter: $global-padding, $factor: $factor); } }

@mixin global-margin($sides: top right bottom left, $factor: 1) {
    @each $side in $sides {
        $property: margin-#{$side};
        @include grid-gutter-property($property, $gutter: $global-padding, $factor: $factor); } }



// ! ---------- GET BORDER FROM GLOBAL BORDER WIDTH

@mixin global-border($side: all, $factor: 1, $color: 'text') {
    $width: $border-width * $factor;
    @if $side == all {
        border: $width solid palette($color); }
    @else {
        border-#{$side}: $width solid palette($color); } }




// ! ---------- FONT INCLUDES

@mixin font-face($family, $weight, $style, $basename, $woff2: true) {

    // Webfont: #{$family} ( #{$weight}  #{$style})

    @font-face {
        font-family: $family;
        @if $woff2 {
            src: font-url('#{$basename}.woff2') format('woff2'), font-url('#{$basename}.woff') format('woff'), font-url('#{$basename}.ttf') format('truetype'); }
        @else {
            src: font-url('#{$basename}.woff') format('woff'), font-url('#{$basename}.ttf') format('truetype'); }
        font-weight: $weight;
        font-style: $style; } }

// ! ---------- ASSET URLS

@function asset-url($filename, $folder: assets, $url-only: false, $cache-buster: $auto-cache-buster) {

    $url: $url-base;

    @if map-has-key($asset-folders, $folder) {
        $url: $url + map-get($asset-folders, $folder); }
    @else {
        @error 'Error: `#{$folder}` is not a valid asset folder.'; }

    $full-url: add-cache-buster($url + $filename, $cache-buster);

    @return if($url-only, unquote($full-url), url('#{$full-url}')); }


@function image-url($filename, $url-only: false, $cache-buster: $auto-cache-buster) {
    @return asset-url($filename, images, $url-only, $cache-buster); }

@function font-url($filename, $url-only: false, $cache-buster: $auto-cache-buster) {
    @return asset-url($filename, fonts, $url-only, $cache-buster); }

@function asset-folder() {
    @return map-get($asset-folders, assets); }

@function image-folder() {
    @return map-get($asset-folders, images); }

@function font-folder() {
    @return map-get($asset-folders, fonts); }


@function add-cache-buster($url, $cache-buster: $auto-cache-buster) {
    @if $cache-buster {

        $parts: explode($url, '.');
        $len:   length($parts);
        $new:   ();

        // Loop through parts & insert cache buster before the last one

        @for $i from 1 through $len {
            @if $i == $len {
                $new: append($new, $cache-buster); }

            $new: append($new, nth($parts, $i)); }

        @return implode($new, '.'); }

    @else {

        @return $url; } }



// ! ---------- ACCESS COLOR MAP BY FUNCTION

@function palette($name, $map: $colors) {
    @if not map-has-key($map, $name) {
        @warn 'Warning: `#{$name}` is not a valid color name.'; }
    @else {
        @return map-get($map, $name); } }



// ! ---------- SERIALIZE DATA FOR INTERCHANGE WITH JS SCRIPTS

@function map-serialize($map) {
    $str: '';

    @each $key, $value in $map {
        $str: $str + $key + '=' + $value + '&'; }

    $str: str-slice($str, 1, -2);

    @return $str; }



// ! ---------- DEFAULT TRANSITIONS

@function transition($properties, $factor: 1, $easing: default) {
    $duration: $transition-duration * $factor;

    @if $easing == default {
        $easing: $transition-easing; }

    $props: ();

    @each $p in $properties {
        $props: append($props, $p $duration $easing, comma); }

    @return $props; }



// ! ---------- CURSORS

@mixin retina-cursor($basename, $fallback: pointer, $origin: 0 0) {
    cursor: $fallback;
    cursor: image-url('#{$basename}@1x.png') $origin, $fallback;   // Legacy
    cursor: image-url('#{$basename}.svg') $origin, $fallback;      // FF
    cursor: -webkit-image-set(image-url('#{$basename}@1x.png') 1x, image-url('#{$basename}@2x.png') 2x) $origin, $fallback; }    // Webkit



// ! ---------- EXPLODE & IMPLODE STRINGS

@function explode($string, $delimiter) {
    $list: ();
    $len:  str-length($string);

    @for $i from 1 through $len {
        $str: str-index($string, $delimiter);

        @if str-length($string) >= 1 and $str == null {
            $list: append($list, $string);
            $string: ''; }

        @if type-of($str) == number {
            $each: str-slice($string, 0, ($str - 1));
            $list: append($list, $each);
            $string: str-slice($string, ($str + 1), $len); } }

    @return $list; }


@function implode($pieces, $glue: "") {
    $result: null;

    @for $i from 1 through length($pieces) {
        $piece: nth($pieces, $i);
        @if type-of($piece) == list {
            $result: unquote("#{$result}#{$glue}#{implode($piece, $glue)}"); }
        @else {
            $result: unquote("#{$result}#{$glue}#{$piece}"); } }

    @if $result != null {
        $result: str-slice($result, str-length($glue) + 1, -1); }

    @return $result; }



// ! ---------- CLEARFIX

%clearfix {
    @include clearfix; }



// ! ---------- HIDE ELEMENTS

@mixin hide-visually {
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip: rect(0, 0, 0, 0);
    position: absolute; }

@mixin hide-text {
    text-indent: -9999px;
    white-space: nowrap;
    overflow: hidden;
    color: transparent; }

@mixin no-tap-highlight {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

%hide-visually {
    @include hide-visually; }

%hide-text {
    @include hide-text; }

%no-tap-highlight {
    @include no-tap-highlight; }



// ! ---------- DRAGGABLE CURSORS

%cursor-dragging {
    cursor: move !important;
    cursor: grabbing !important; }

%cursor-draggable {
    cursor: move !important;
    cursor: grab !important; }

%cursor-sorting {
    cursor: move !important; }

%cursor-sortable {
    cursor: move !important; }



// ! ---------- DISABLE SELECTION AND DRAGGING

%not-draggable {
    user-drag: none; }

%not-selectable {
    user-select: none; }




// ! ---------- NEUTRAL BUTTON STYLE

@mixin neutral-button() {

    appearance: none;
    overflow: visible;
    margin: 0;
    padding: 0;
    border: 0;
    color: inherit;
    background: transparent;
    font: inherit;
    line-height: normal;
    text-decoration: none;
    text-align: inherit;
    cursor: pointer;
    user-select: text;
    transition: transition(color);
    outline: none;
    &:hover {
 }        // color: palette('text')

    // Remove mystery padding in Gecko browsers

    &::-moz-focus-inner {
        padding: 0;
        border: 0; } }

%neutral-button {
    @include neutral-button(); }


@mixin min-height--content($num-headers: 1, $property: min-height, $factor: 1) {
    $height: 100vh * $factor;
    #{$property}: $height;
    #{$property}: calc(#{$height} - #{$header-height--nav-as-overlay * $num-headers * $factor});
    #{$property}: calc(#{$height} - #{$header-height--nav-as-overlay * $num-headers * $factor} - ( var(--title-height) * #{$factor} ) );
    @include layout--nav-always-visible {
        #{$property}: calc(#{$height} - #{$header-height--nav-always-visible * $num-headers * $factor}); } }

@mixin min-height--header($num-headers: 1, $property: min-height) {
    #{$property}: $header-height--nav-as-overlay * $num-headers;
    @include layout--nav-always-visible {
        #{$property}: $header-height--nav-always-visible * $num-headers; } }
