// ! ---------- PAGE TITLE


.page-title {
    display: block;
    width: 100%;
    text-align: center;
    padding: .5em 1em 0;

    @include layout--single-column {
        color: palette('highlight');
        background: palette('background');
        border-bottom: 2px solid palette('highlight');
        text-transform: lowercase;
        padding: .5em 1em;
        @include accent-typography; }
    @include layout--multi-column {
        @include headline-115; }

    .template-service-list &,
    .template-client-list &,
    .template-blog-list &,
    .template-blog-post &,
    .template-about &,
    .template-contact &, {
        @include layout--multi-column {
            @include hide-visually(); } } }


// ! ---------- A11Y SKIP LINKS


.skip-link {
    @extend %hide-visually; }



.cookie-notice {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    transform: translateY(150%);
    transition: transition(transform, $factor: 2);
    color: palette('highlight');
    z-index: $z-index-cookies;
    @extend %headline-typography;
    text-align: center;
    pointer-events: none;

    .is-cookie-notice-visible & {
        transform: none; }

    @include breakpoint(all only) {
 } }        // font-size: $fontsize-s

.cookie-notice__inner {
    margin: 0 auto;
    max-width: 90vw;
    background: white;
    padding: .3em 1em;
    padding-right: 2em;
    display: inline-block;
    position: relative;
    pointer-events: auto; }

.cookie-notice__accept {
    @extend %neutral-button;
    position: absolute;
    top: 50%;
    right: .5em;
    width: .85em;
    height: .85em;
    transform: translateY(-50%) scale(1.2);

    span {
        @extend %hide-visually; }

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%) rotate(45deg);
        height: 1px;
        background: palette('highlight'); }

    &::after {
        transform: translate(-50%, -50%) rotate(-45deg); } }




// ! ---------- LOGO


.logo {
    svg {
        height: $logo-height--nav-as-overlay;
        width: $logo-height--nav-as-overlay * 2.35;
        margin-top: ($header-height--nav-as-overlay - $logo-height--nav-as-overlay) / 2;
        margin-left: ($header-height--nav-as-overlay - $logo-height--nav-as-overlay) / 2;
        @include layout--nav-always-visible {
            height: $logo-height--nav-always-visible;
            width: $logo-height--nav-always-visible * 2.35;
            margin-top: ($header-height--nav-always-visible - $logo-height--nav-always-visible) / 2;
            margin-left: ($header-height--nav-always-visible - $logo-height--nav-always-visible) / 2; } } }

.logo--text {
    @extend %hide-visually; }

// SVG paths

#logo { // logo parent path
    fill: palette('text');
    .template-home & {
        fill: palette('text-inverted'); }
    .is-nav-visible & {
        @include layout--nav-as-overlay {
            fill: palette('text-inverted'); } } }

#logo-text { // company name only
    transition: transition(opacity);
    opacity: 0;

    .template-home &,
    a:hover & {
        opacity: 1; } }




// ! ---------- LANGUAGE SWITCHER


.language-switcher {
    li {
        > a,
        > span {
            &::before {
                content: attr(data-lang-code);
                text-transform: uppercase; }
            span {
                @extend %hide-visually; } } } }

.language-switcher__header {
    @extend %hide-visually; }




// ! ---------- HOMEPAGE


.home__claim {

    display: flex;
    justify-content: center;
    align-items: center;

    @include min-height--content($property: height);

    background: palette('highlight');
    color: palette('text-inverted');
    @include headline;
    // @include accent-typography
    font-size: 9.5vw;
    @include breakpoint(medium) {
        font-size: 7.25vw; }
    text-align: center;

    li {
        &:nth-child(1) {
            transform: translateX(.5em); }
        &:nth-child(2) {
            transform: translateX(-1em); }
        &:nth-child(3) {
            transform: translateX(1.1em); } } }

.home__claim-inner {
    max-width: 12em;
    @extend %text-above-parallax; }

.home__content {
    width: 100%;
    display: flex;
    flex-direction: row;
    @extend %max-width-container; }

.home__logo,
.home__intro {
    flex: 0 0 100%;
    @include layout--multi-column {
        flex-basis: 50%; } }

.home__logo {
    @include layout--single-column {
        display: none; } }

.home__intro {
    padding-top: $one-line-height * 2;
    padding-bottom: $one-line-height * 2;
    @include global-padding(left right); }

.home__logo {
    background: image-url('logo-symbol.svg') no-repeat 50% 50% / auto 70%; }

.home__info-toggle {
    @extend %read-more-link; }

.home__info {
    font-size: 1.1em;
    @extend %max-width-container;
    @extend %text-above-parallax;
    padding-top: $one-line-height * 2;
    padding-bottom: $one-line-height * 2;
    @include global-padding(left right);
    @include layout--multi-column {
        padding-top: $one-line-height * 3;
        padding-bottom: $one-line-height * 3; } }



// ! ---------- SERVICE LIST & PREVIEW


.service-list {}

.service-list__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }

.service-list__service {
    flex: 1 1 50%;
    display: flex;
    overflow: hidden;
    border-bottom: 2px solid palette('highlight');
    @include breakpoint(small down) {
        &:nth-child(odd) {
            border-right: 2px solid palette('highlight'); } }
    @include breakpoint(medium) {
        flex-basis: 25%;
        border-right: 2px solid palette('highlight');
        &:last-child {
            border-right-width: 0; } }

    a {
        display: flex;
        width: 100%; } }

.service-preview {
    width: 100%;
    padding: .75em 1em;
    transition: transition(background);
    text-align: center;
    display: flex;
    flex-direction: column;
    hyphens: auto;
    @include breakpoint(medium) {
        padding: 1em; }
    @include breakpoint(all only) {
 }        // font-size: ($fontsize-m + $fontsize-m-l) / 2

    a:hover &,
    &.is-active {
        background: palette('highlight');
        color: palette('text-inverted'); } }

.service-preview__title,
.service-preview__image {
    width: 100%; }

.service-preview__title, {
    order: 2;
    margin-top: auto;
    @extend %headline;
    @include breakpoint(small down) {
 } }        //font-size: $fontsize-m

.service-preview__image {
    order: 1; }

.service-preview__image-inner {
    @extend .ratio-container;
    padding-bottom: 105%;

    img, svg {
        width: 100%;
        max-width: 20em;
        pointer-events: none;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); }

    svg {
        fill: palette('highlight');
        transition: transition(stroke fill);
        a:hover &,
        .is-active & {
            fill: palette('text-inverted'); }
        [fill]:not([fill='none']) {
            fill: palette('highlight');
            transition: transition(stroke fill);
            a:hover &,
            .is-active & {
                fill: palette('text-inverted'); } }
        [stroke] {
            stroke: palette('highlight');
            transition: transition(stroke fill);
            a:hover &,
            .is-active & {
                stroke: palette('text-inverted'); } } } }




// ! ---------- SERVICE DETAIL


.service {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: $fontsize-s-m;

    background: white;
    @supports (mix-blend-mode: darken) {
        background: palette('background'); } }

.service__video {
    order: 1;
    flex: 0 0 100%;
    overflow: hidden;
    position: relative;
    text-align: center;

    background: white;
    @supports (mix-blend-mode: darken) {
        background: palette('background'); }

    video {
        mix-blend-mode: darken;
        width: 80%;
        margin: 0 auto; }

    &.fit--cover {
        video {} }

    object-fit {
        mix-blend-mode: darken;
        video {
            mix-blend-mode: normal; } }

    @include layout--multi-column {
        flex-basis: 53%;

        > video {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            transform: translate(-50%, -50%);
            object-fit: contain;
 } }            // object-fit: cover

    &.fit--contain {
        object-fit {
            transform: scale(.8); }
        video {
            width: 80%;
            object-fit: contain; }
        @include layout--multi-column {
            > video {
                width: 80%;
                height: 80%; } } }

    &.fit--cover {
        @include layout--single-column {
            video {
                max-height: 80vw; } } } }


.service__content {
    order: 2;
    flex: 0 0 100%;
    overflow: hidden;
    @include layout--multi-column {
        flex-basis: 47%; } }

.service__process {
    order: 4;
    flex: 0 0 100%; }

.service__process-header {
    order: 3;
    width: 100%;
    background: palette('highlight');
    color: palette('text-inverted');
    font-size: 16vw;
    text-align: center;
    line-height: 1;
    text-transform: uppercase;
    padding: .1em 0 0 0; }

.service .content-footer {
    order: 5;
    flex: 0 0 100%; }

.service__title,
.service__sub-headline {
    text-align: center;
    @include layout--multi-column {
        text-align: left; } }

.service__title,
.service__sub-headline,
.service__text {
    @include global-padding(left right, $factor: 3);
    @include layout--multi-column {
        @include global-padding(left right, $factor: 1); } }

.service__title {
    @extend %headline-70;
    color: palette('highlight');
    margin-bottom: $one-line-height--absolute;
    margin-top: $one-line-height--relative * 1.3; }

.service__sub-headline {
    text-transform: uppercase;
    font-size: 1.075em;
    margin-bottom: $one-line-height--absolute;
    @include breakpoint(small down) {
        font-size: 1.15em; } }

.service__text {
    margin-bottom: 4  * $one-line-height--relative;
    @include breakpoint(small down) {
        font-size: 1.2em; } }





// ! ---------- SERVICE PROCESS LIST


.process-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }

.process-list__process {
    flex: 0 0 50%;
    overflow: hidden;
    border-right: 2px solid palette('highlight');

    @include breakpoint(medium) {
        flex-basis: 25%; }

    &:nth-child(4n+0) {
        border-right-width: 0; }
    @include breakpoint(small down) {
        &:nth-child(2n+0) {
            border-right-width: 0; } } }


%force-break-after {
    &::after {
        content: '\A';
        white-space: pre;
        width: 1px;
        overflow: hidden; } }

.process-list__title {
    padding: 1em 1em 1em 1em;
    color: palette('highlight');
    text-align: center;
    @extend %headline;
    font-size: $fontsize-m;
    border-top: 2px solid palette('highlight');
    hyphens: auto;

    // Force break between words if using few words

    &.word-count--2 {
        div span:nth-child(1) {
            @extend %force-break-after; } }

    &.word-count--3,
    &.word-count--4 {
        div span:nth-child(2) {
            @extend %force-break-after; } } }

.process-list__label {
    display: block;
    @extend %accent-typography;
    user-select: none;
    margin-bottom: .25em;
    font-size: 8em;
    line-height: .9;
    will-change: transform;
    @include breakpoint(medium) {
        font-size: 8em; }
    @include breakpoint(small down) {
        padding-top: .1em; }

    .js & {
        will-change: transform;
        transition: transition(opacity transform);
        transform: translate3d(0, -120%, 0); }

    .is-in-view & {
        transform: translate3d(0, 0, 0);
        .is-process-list-in-view.is-not-scrolling & {
            animation: process-label-jump 1.5s ease-in-out backwards; } } // alternate

    @for $i from 1 through 4 {
        .js .process-list__process:nth-child(#{$i}) & {
            transition-delay: $transition-duration*$i;
            animation-delay: $transition-duration*$i !important; } }

    span {
        @extend %hide-visually; } }

@keyframes process-label-bob {
	0% {
		transform: translate3d(0, 0, 0); }
	50% {
        transform: translate3d(0, -3%, 0); }
	100% {
		transform: translate3d(0, 0, 0); } }

@keyframes process-label-jump {
    0% {
        transform: translate3d(0, 0, 0); }
    20% {
        transform: translate3d(0, -3%, 0); }
    30% {
        transform: translate3d(0, 0, 0); }
    40% {
        transform: translate3d(0, -1.5%, 0); }
    60% {
        transform: translate3d(0, 0, 0); }
    100% {
        transform: translate3d(0, 0, 0); } }

.process-list__content {
    font-size: $fontsize-s;
    padding: 1em;
    max-width: 18em;
    margin: 0 auto;
    @include breakpoint(small) {
        padding: 1.5em 2em; }
    @include breakpoint(medium) {
        padding: 1em;
        padding-top: $one-line-height * 2;
        padding-bottom: $one-line-height * 2; }

    li {
        margin-bottom: $one-line-height--relative * 0.5; } }




// ! ---------- BLOG LIST / PREVIEW


.blog__list {
    width: 100%;
    position: relative;

    .template-blog-post & {
        border-bottom: 2px solid palette('highlight'); }

    // Arrow right: scroll indicator

    &::after {
        position: absolute;
        top: 50%;
        right: .025em;
        transform: translateY(-50%);
        @extend %icon;
        content: '\e800'; // arrow right
        color: palette('highlight');
        font-size: 30vh;
        pointer-events: none;
        user-select: none;
        transition: transition(opacity font-size right);

        @include layout--multi-column {
            display: none; }

        .is-blog-scrolled-right & {
            opacity: 0; }

        .template-blog-post & {
            font-size: 9vh;
            right: .075em; } } }

.blog__list-inner {
    display: flex;
    flex-direction: row;

    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;

    .blog__preview {
        flex: 0 0 12rem;
        overflow: hidden;
        @include layout--multi-column {
            flex: 1 0 0px; } } }

.blog__preview {
    border-right: 2px solid palette('highlight');
    .is-blog-scrolled-right &:last-child {
        border-right-width: 0; }
    @include layout--multi-column {
        &:last-child {
            border-right-width: 0; } } }

.blog__preview, {
    display: block;
    transition: transition(color background), transition(min-height, $factor: 3);
    min-height: 1em;

    .template-blog-list & {
        @include min-height--content($property: min-height); }

    .is-resizing & {
        transition: none; } }

.blog-preview {
    height: 100%;
    display: flex;
    flex-direction: column;
    hyphens: auto;
    transition: transition(color background);

    .template-blog-post & {
        cursor: pointer; }

    &.is-active,
    .template-blog-post &:hover, {
        background: palette('highlight');
        color: palette('text-inverted'); } }

.blog-preview__header {
    margin-bottom: $one-line-height; }

.blog-preview__title,
.blog-preview__categories,
.blog-preview__posts, {
    padding: .5rem .75rem;
    line-height: $lineheight-s;
    @include breakpoint(small) {
        padding: .75rem 1.25rem; } }

.blog-preview__title {
    @include headline;
    font-size: ($fontsize-m + $fontsize-m-l) / 2;
    @include breakpoint(large) {
        font-size: ($fontsize-m-l + $fontsize-l) / 2; }
    @include breakpoint(xlarge) {
        font-size: $fontsize-l; }

    text-align: left;
    padding-bottom: 0 !important;
    min-height: var(--blog-preview-title-height); }

.blog-preview__categories,
.blog-preview__posts, {
    padding-top: $one-line-height;
    padding-bottom: $one-line-height;
    // transition: transition(color background), transition(min-height, $factor: 3)
    transition: transition(max-height margin padding opacity, $factor: 3);
    font-size: $fontsize-s;
    line-height: $lineheight-l;

    .template-blog-post & {
        opacity: 0;
        max-height: 0;
        padding-top: 0;
        padding-bottom: 0; } }

.blog-category-list,
.blog-post-list, {
    color: palette('highlight');

    .is-active & {
        color: palette('text-inverted');
        transition: none; } }





// ! ---------- BLOG POST


.blog-post {
    @extend %max-width-container;
    padding-top: $one-line-height * 2;
    @include global-padding(left right);
    //transition: transition(padding)
    .template-blog-list & {
        padding-top: 0; } }

.blog-post__title {
    @extend %headline-115; }

.blog-post__date {
    margin: $one-line-height 0;
    color: palette('highlight');
    text-align: center;
    @include breakpoint(medium) {
        font-size: $fontsize-m-l;
        text-align: left; } }

.blog-post__content {
    padding-bottom: $one-line-height * 3;
 }    // overflow-x: hidden

.blog-post__content,
.blog-post__title,
.blog-post__date {
    @extend %text-above-parallax; }




// ! ---------- CONTENT FOOTER / FOOTER INFO


.content-footer {
    width: 100vw;

    background: palette('highlight');
    color: palette('text-inverted');
    @extend %headline-typography;
    font-size: 1.075em;
    text-align: center;

    .template-blog-list &,
    .template-blog-post & {
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw; }

    a {
        text-decoration: underline; } }

.content-footer__inner {
    @extend %max-width-container--wide;
    padding: 1em;
    &:empty {
        display: none; }
    @include breakpoint(small) {
        padding: 1em; } }




// ! ---------- ABOUT

.about {
    @include layout--multi-column {
        background: linear-gradient(to right, palette('background') 50%, palette('highlight') 50%); } }

.about__header {
    width: 100%;
    overflow: hidden;
    position: relative;
    background: palette('background');

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 10%;
        @include layout--multi-column {
            object-position: 50% 10%; } } }

.about__header {
    @include min-height--content($property: height, $factor: 1/2);
    @include layout--multi-column {
        @include min-height--content($property: height, $factor: 1); } }

.about__content-inner,
.about__info-inner {
    @include min-height--content($property: min-height, $factor: 1/2); }

.about__content {
    width: 100%;
    @extend %text-above-parallax; }

.about__content-inner {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    border-top: 2px solid palette('highlight');

    @include layout--multi-column {
        flex-direction: row; } }

.about__intro,
.about__byline {
    flex: 0 0 100%;
    @include layout--multi-column {
        flex-basis: 50%; } }

.about__intro {
    order: 1;
    display: flex;
    flex-direction: column;
    // justify-content: center
    align-items: center;
    background: palette('background');

    @include layout--multi-column {
        @include min-height--content($property: height, $factor: 1);
        overflow-y: auto; }

    > div {
        margin-top: auto;
        margin-bottom: auto;
        padding: #{$one-line-height * 2} 0;
        @include global-padding(left right);
        @include layout--multi-column {
            max-width: 24em; } } }

.about__byline {
    @include layout--multi-column {
        order: 2;
        @include min-height--content($property: height); }
    @include layout--single-column {
        @include min-height--content($property: min-height, $factor: 1/2); }

    background: palette('highlight');
    color: palette('text-inverted');
    @extend %accent-typography;
    text-align: center;
    @extend %headline-70;
    @extend %headline-115;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
        flex: 1;
        max-width: 14em;
        padding-top: $one-line-height;
        padding-bottom: $one-line-height;
        @include global-padding(left right);
        hyphens: none;
        font-size: .85em;

        @include breakpoint(small) {
            font-size: inherit; }
        @include layout--multi-column {
            padding-top: $one-line-height*2;
            padding-bottom: $one-line-height*2; } } }

.about__info-toggle {
    @extend %read-more-link; }

.about__info {
    background: palette('background');
    font-size: 1.1em; }

.about__info-inner {
    @extend %max-width-container;
    padding-top: $one-line-height * 2;
    padding-bottom: $one-line-height * 2;
    @include global-padding(left right);
    @include layout--multi-column {
        padding-top: $one-line-height * 3;
        padding-bottom: $one-line-height * 3; } }



// ! ---------- CONTACT


.contact {}

// Main row

.contact__main {
    display: flex;
    flex-direction: column;
    @include layout--multi-column {
        flex-direction: row;
        @include min-height--content($num-headers: 2); } }

// Children of main row

.contact__info,
.contact__map {
    @include layout--multi-column {
        flex: 0 0 50%; } }

// Info

.contact__title,
.contact__sub-headline {
    color: palette('highlight');
    text-align: center; }

.contact__title {
    @extend %headline-70; }

.contact__sub-headline {
    @extend %sub-headline;
    margin-top: .5em; }

.contact__info {
    order: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $one-line-height--absolute*1.5 .75em;
    @include layout--single-column {
        border-top: 2px solid palette('highlight'); }
    @include layout--multi-column {
        border-left: 2px solid palette('highlight'); } }

.contact__publisher {
    @extend %sub-headline; }

.contact__info-table {
    margin-top: 2em;
    @include breakpoint(large) {
        min-width: 19em; }
    border: 2px solid palette('highlight');
    font-size: $fontsize-s-m;

    > div {
        padding: .6em 1em .5em 1em; }
    .contact__profiles {
        padding: 0; }

    > div + div {
        border-top: 2px solid palette('highlight'); }

    a[href^='tel:'] {
        @extend %accent-typography; } }

.contact__profiles {
    display: flex;
    align-items: stretch;
    justify-content: center;
    width: 100%;
    a {
        flex: 1;
        padding: .8em 1em .6em 1em;
        text-align: center;
        transition: transition(color background);
        &:hover {
            background: palette('highlight');
            color: palette('text-inverted'); } }
    a + a {
        border-left: 2px solid palette('highlight'); }


    a[href*="linkedin."], a[href*="xing."] {
        span {
            @extend %hide-visually; }
        i {
            display: inline-block;
            width: 100%;
            @extend %icon;
            font-size: 1.35em;
 } }            // transform: scale(1.35)

    a[href*="linkedin."] i {
        @extend %icon-linkedin; }

    a[href*="xing."] i {
        @extend %icon-xing; } }

// Map

.contact__map {
    position: relative;

    @include layout--single-column {
        height: 40vh;
        min-height: 500px; }
    @include breakpoint(all only) {
        min-height: 375px; }

    .map, .map__map {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; } }

// Secondary row

.contact__secondary {
    background: white;
    border-top: 2px solid palette('highlight');
    display: flex;
    flex-direction: row;
    justify-content: center;

    @include layout--single-column {
        @include hide-visually; } }


// Children of secondary row

.contact__secondary-column {
    flex: 0 0 50%;
    color: palette('highlight');
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: $grid-row-max-width--wide/2; }

.contact__secondary-content {
    padding-top: 2 * $one-line-height;
    padding-bottom: 5 * $one-line-height;
    padding-left: 1em;
    padding-right: 1em;
    max-width: 40em; } // 30em

// Secondary row content

.contact__secondary-header {
    @extend %headline-60;
    color: palette('highlight');

    @include min-height--header($property: min-height);
    @include min-height--header($property: line-height); }

.contact__legal-1 {
    font-size: $fontsize-s; }

.contact__legal-2 {
    font-size: $fontsize-s; }


.background-image-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pverflow: hidden;
    pointer-events: none;
    z-index: $z-index-parallax;
    // position: relative

    @include breakpoint(small down) {
        .template-blog-list &,
        .template-blog-post & {
            display: none; } }

    @include layout--single-column {
        .template-about & {
                height: 80%; } }

    @if false {
        @supports (mix-blend-mode: darken) {
            mix-blend-mode: darken; } } }



.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 10rem;
    @include breakpoint(small) {
        width: 20rem; }

    opacity: 0;
    transition: transition(opacity);
    &.is-setup {
        opacity: 1; }

    .template-blog-list & {
        opacity: 0; }

    img, video {
        width: 100%;
        height: auto;
        transform: translate(-50%, -50%);
        transition: none; }

    &.is-sequence {
        img {
            opacity: 0;
 } }            // visibility: hidden

    &.is-sequence.has-not-loaded {
        img:first-child {
            opacity: 1;
 } }            // visibility: visible

    &.is-sequence.has-loaded { // .has-looped-once
        img.is-current-frame {
            opacity: 1;
 } }            // visibility: visible

    img ~ img {
        position: absolute;
        top: 0;
        left: 0; }

    video {
        display: none;
        @supports (mix-blend-mode: darken) {
            display: block; } }

    video + img {
        @supports (mix-blend-mode: darken) {
            display: none; } } }



.basic-page {}


.basic-page__content {
    padding: $one-line-height*2 0;
    @include global-padding(left right);
    @extend %max-width-container; }

.template-client-list {
    .basic-page__content {
        @extend %headline-60;
        line-height: 1.5; } }


// ! ---------- COMING SOON


$coming-soon-padding: 3rem;
$coming-soon-border: 2px;

$b: $coming-soon-border;

.coming-soon {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    @include breakpoint(landscape) {
        flex-direction: row; }

    color: palette('highlight'); }

.coming-soon__column {
    flex-basis: 50%;
    flex-shrink: 0;
    flex-grow: 0;

    font-size: .8rem;
    padding: $coming-soon-padding * 0.35;
    @include breakpoint(small) {
        padding: $coming-soon-padding * 0.6; }
    @include breakpoint(medium) {
        font-size: 1rem;
        padding: $coming-soon-padding * 1; }

    &:first-child {
        border: 1px solid palette('highlight');
        border-width: $b 0 0 0;
        @include breakpoint(landscape) {
            border-width: 0 0 0 $b; } } }

.coming-soon__info {
    order: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $font-stack-accent; }

.coming-soon__info-inner {
    padding-bottom: 2.5rem;
    @include breakpoint(landscape) {
        padding-bottom: 0; } }

.coming-soon__title {
    text-transform: uppercase;
    font-size: 1.3em; }

.coming-soon__sub-headline {
    font-size: 1.15em; }

.coming-soon__contact {
    margin-top: 1.5em;
    border: 1px solid palette('highlight');
    border-width: $b $b 0 $b;

    span, a {
        display: block;
        border-bottom: $b solid palette('highlight');
        padding: .5em .65em; }

    br {
        display: none; } }

.coming-soon__name {
    text-transform: uppercase; }

.coming-soon__coming-soon {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: .4em;
    font-family: $font-stack-default;
    font-size: 1.9em;
    z-index: 2;

    @include breakpoint(340) {
        font-size: 2.1em; }
    @include breakpoint(landscape) {
        padding: inherit;
        width: 50%;
        font-size: 2.3em; } }

.coming-soon__video {
    order: 1;
    position: relative;

    @supports (mix-blend-mode: darken) {
        background: palette('background'); }

    video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 80%;
        max-height: 80%;
        width: auto;
        height: auto;
        z-index: 1;

        @supports (mix-blend-mode: darken) {
            mix-blend-mode: darken; } } }
