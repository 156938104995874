// ! ---------- FONTS


$font-favorit: 'Favorit';
$font-sectra: 'Sectra';
$font-america: 'AmericaMono';
$font-icons: 'HH-Icons';

$font-stack-default: $font-favorit, Arial, sans-serif;
$font-stack-headline: $font-sectra, Garamond, Times, serif;
$font-stack-accent: $font-america, monospace;
$font-stack-icons: $font-icons;

@include font-face($font-favorit, normal, normal, 'favorit/Favorit-Regular', $woff2: false);
@include font-face($font-sectra, normal, normal, 'gt-sectra/GT-Sectra-Medium');
@include font-face($font-america, normal, normal, 'gt-america/GT-America-Mono-Regular');
@include font-face($font-icons, normal, normal, 'fontello/fontello');



// ! ---------- TYPOGRAPHY


%basic-typography {
    font-family: $font-stack-default;
    font-weight: normal;
    letter-spacing: $letterspacing-base; }

@mixin headline-typography {
    font-family: $font-stack-headline;
    letter-spacing: $letterspacing-m; }

%headline-typography {
    @include headline-typography; }

@mixin accent-typography {
    font-family: $font-stack-accent; }

%accent-typography {
    @include accent-typography; }

@mixin headline {
    @include headline-typography;
    text-align: center; }

%headline {
    @include headline; }

%headline-60 {
    @extend %headline;
    font-size: ($fontsize-m + $fontsize-m-l) / 2;
    @include breakpoint(medium) {
        font-size: $fontsize-m-l; } }

@mixin headline-70 {
    @include headline;
    font-size: ($fontsize-m-l + $fontsize-l) / 2;
    @include breakpoint(xlarge) {
        font-size: $fontsize-l; } }

%headline-70 {
    @include headline-70; }

@mixin headline-115 {
    @include headline;
    font-size: ($fontsize-l + $fontsize-l-xl) / 2;
    @include breakpoint(large) {
        font-size: ($fontsize-l + $fontsize-l-xl) / 2; }
    @include breakpoint(xlarge) {
        font-size: $fontsize-l-xl; } }

%headline-115 {
    @include headline-115; }

@mixin headline-130 {
    @include headline;
    font-size: $fontsize-l;
    @include breakpoint(small) {
        font-size: ($fontsize-l + $fontsize-xl) / 2; }
    @include breakpoint(medium) {
        font-size: $fontsize-xl; } }

%headline-130 {
    @include headline-130; }

%sub-headline {
    text-transform: uppercase;
    letter-spacing: $letterspacing-m; }




%max-width-container {
    margin-left: auto;
    margin-right: auto;
    max-width: $grid-row-max-width; }

%max-width-container--wide {
    @extend %max-width-container;
    max-width: $grid-row-max-width--wide; }

%read-more-link {
    @extend %accent-typography;
    color: palette('highlight');
    font-size: .9em;
    cursor: pointer;
    transition: transition(color opacity);
    &:hover {
        color: palette('text'); }

    span {
        @extend %hide-visually; }

    &::after {
        display: inline-block;
        content: attr(data-text-show);
        .is-toggled & {
            content: attr(data-text-hide); } } }

%text-above-parallax {
    position: relative;
    z-index: $z-index-text-above; }



// ! ---------- MEDIA QUERIES


// Save breakpoints to meta tag using Foundation utility method
// Read back in via JS

.js-breakpoints {
    font-family: '#{-zf-bp-serialize($breakpoints)}'; }



// ! ---------- BASIC STYLES


html {
    @extend %basic-typography;
    box-sizing: border-box;
    font-size: $fontsize-base;
    @include breakpoint(medium) {
        font-size: $fontsize-base--medium; }

    -ms-overflow-style: -ms-autohiding-scrollbar;

    --title-height: 0px;
    --blog-preview-title-height: 0px; }

*,
*::before,
*::after {
    box-sizing: inherit; }

body {
    color: palette('text');
    font-size: $fontsize-m;
    line-height: $lineheight-base;

    @supports (mix-blend-mode: darken) {
        background: palette('background'); }

    @if $font-smoothing {
        -webkit-font-smoothing:  antialiased;
        -moz-osx-font-smoothing: grayscale; } }

a {
    color: inherit;
    text-decoration: none;
    transition: transition(color);

    &:hover, &.hover {
        text-decoration: none;
        color: inherit; } }

img {
    max-width: 100%;
    height: auto;
    -ms-interpolation-mode: bicubic; }

input,
td {
    @extend %basic-typography;
    font-size: 1em; }

::placeholder {
    line-height: normal; }

select {
    width: 100%; }

.hide-visually,
.sr-only {
    @extend %hide-visually; }

.hide-text {
    @extend %hide-text; }

// Show/hide elements in JavaScript

.is-visible {
    display: block !important; }

.is-hidden {
    display: none !important; }

.is-loading,
.is-loading input,
.is-loading a {
    cursor: wait !important; }

.hyphenate {
    hyphens: auto; }


// ! ---------- TEXT SELECTION


::selection {
    background: palette('selection'); }

::-moz-selection {
    background: palette('selection'); }



// ! ---------- IMAGE LAZYLOADING


// Fade in after load

.lazyload,
.lazyloading {
    opacity: 0; }

.lazyloaded {
    opacity: 1;
    transition: transition(opacity); }


// Blur lazyloaded images before unveiling

.lazyloadblur {
    opacity: 1;
    filter: blur(20px);
    transition: transition(filter);

    &.lazyloaded {
        filter: blur(0); } }


// Hide lazyload images on non JS browsers

.no-js .lazyload {
    display: none !important; }

.no-js noscript .lazyload {
    display: block !important;
    opacity: 1; }



// ! ---------- FLOATING


.clearfix {
    @include clearfix; }



// ! ---------- INTRINSIC RATIOS


.ratio-container {
    position: relative;
    height: 0;
    max-width: 100%;
    overflow: hidden;

    img, video, iframe, object, embed, svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; } }

.ratio-1-1 {
    padding-bottom: 100%; }
.ratio-3-2 {
    padding-bottom: 66.6%; }
.ratio-4-3 {
    padding-bottom: 75%; }
.ratio-5-3 {
    padding-bottom: 60%; }
.ratio-5-4 {
    padding-bottom: 80%; }
.ratio-16-9 {
    padding-bottom: 56.25%; }
.ratio-16-10,
.ratio-8-5 {
    padding-bottom: 62.5%; }



// ! ---------- TEXT BLOCKS


.text-block {

    hyphens: auto;

    a, a:hover {}

    h1, h2, h3, h4, h5, h6 {
        font-size: $fontsize-l;
        margin-bottom: $one-line-height--absolute; }

    p, ul, ol, figure, img, table, address, .TextformatterVideoEmbed {
        margin-bottom: $one-line-height;
        &:last-child {
            margin-bottom: 0; } }

    figure img,
    .table-wrapper table {
        margin-bottom: 0; }

    .table-wrapper {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        padding-bottom: $one-line-height;
        @include global-margin(left right, $factor: -1);
        @include global-padding(right, $factor: 1);
        -ms-overflow-style: none;
        table {
            @include global-margin(left right); } }

    table {
        hyphens: none; }

    ul {
        display: table;
        list-style: none;

        > li {
            display: table-row;

            &::before {
                display: table-cell;
                padding-right: .75em;
                content: "→";
                font-size: 1.25em;
                position: relative;
                top: .125em;
                color: palette('highlight'); } } }

    ol {
        display: table;
        list-style: none;
        counter-reset: table-ol;

        > li {
            display: table-row;
            counter-increment: table-ol;

            &::before {
                display: table-cell;
                text-align: right;
                padding-right: .9375em;
                content: counter(table-ol) "."; } } }

    table {
        margin-bottom: $one-line-height * 1.5;
        max-width: 100%;
        border: 2px solid palette('highlight');
        border-collapse: collapse; }

    .TextformatterVideoEmbed {
        margin: #{$one-line-height * 1.5} 0; }

    th, td {
        border: 2px solid palette('highlight');
        padding: .5em .8em; }
    th {
        font-size: .75em; }
    td {
        font-size: .85em; } }

.text-block + .text-block {
    padding-top: $one-line-height; }

.text-block--uniform-size {
    h1, h2, h3, h4, h5, h6 {
        font-size: 1em; } }


.text-block--fluid-lists {

    ul {
        display: block;
        > li {
            display: list-item;
            &::before {
                display: inline-block;
                padding-right: 1.75em; } } } }

// ! ---------- ICONS


%icon {
    font-family: $font-icons;
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;

    transition: transition(transform);

    /* font-size: 120%; */

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

[class^="icon-"],
[class*=" icon-"] {
    &::before {
        @extend %icon; } }

%icon-linkedin {
    &:before {
        content: '\e801'; } }

%icon-xing {
    &:before {
        content: '\e802'; } }

%icon-arrow {
    &:before {
        content: '\e800'; } }

.icon-linkedin {
    @extend %icon-linkedin; }
.icon-xing {
    @extend %icon-xing; }
.icon-arrow {
    @extend %icon-arrow; }
